import type { PropsWithChildren } from 'react';
import { generatePath, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';
import { Form, handleFormSubmit, useTranslateSubmitError } from 'modules/forms';
import config from 'config';

import { Fields, SecretType, TOTP_VALUE_PLACEHOLDER } from '../../constants';
import { updateSecret } from '../../services/actions';
import { selectUpdateSecretFormInitialValues } from '../../services/selectors';
import { secretFormSchema, type SecretFormSchemaType, type SecretFormValues } from '../../schemas';

export interface UpdateSecretFormProviderProps extends PropsWithChildren {}

export const UpdateSecretFormProvider = ({ children }: UpdateSecretFormProviderProps) => {
    const { id } = useParams<{
        id: string;
    }>();

    if (!id) {
        throw new Error('Missing secret ID');
    }

    const { state } = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const defaultValues = useAppSelector(state =>
        selectUpdateSecretFormInitialValues(state, {
            id,
        }),
    );

    const translateSubmitError = useTranslateSubmitError<SecretFormValues>();

    return (
        <Form<SecretFormSchemaType, SecretFormValues>
            defaultValues={defaultValues}
            schema={secretFormSchema}
            onSubmit={async (values, { setError, setValue }) => {
                if (values.type === SecretType.Password) {
                    // Do not keep secret key, replace it with placeholder and send value to backend
                    setValue(Fields.TOTP, values.TOTP ? TOTP_VALUE_PLACEHOLDER : null);
                }

                const error = await handleFormSubmit<SecretFormValues>((values, actions) => {
                    dispatch(updateSecret(id, values, actions));
                })(values);

                if (error) {
                    const translatedError = translateSubmitError(...error);
                    setError(...translatedError);
                    return;
                }

                if (state?.from) {
                    navigate(state.from);
                } else {
                    navigate(generatePath(config.routes.secret, { id }));
                }
            }}
        >
            {children}
        </Form>
    );
};
