import { FormattedMessage } from 'react-intl';

import { FormField, Label, TextareaInput } from 'modules/forms';

import { Fields } from '../../../constants';

export const SecureNoteFields = () => (
    <FormField
        label={
            <Label>
                <FormattedMessage id="secret.secureNote.label" />
            </Label>
        }
        name={Fields.SECURE_NOTE}
    >
        <TextareaInput name={Fields.SECURE_NOTE} rows={5} />
    </FormField>
);
