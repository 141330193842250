import { FormattedMessage } from 'react-intl';
import { useController } from 'react-hook-form';

import { FormField, Label, SelectInput } from 'modules/forms';

import type { SecretFormValues } from '../../../schemas';
import { Fields, SecretType } from '../../../constants';
import { SelectOption } from './SelectOption';

import * as felaRules from './SecretTypeField.rules';

const name = Fields.TYPE;

export const SecretTypeField = () => {
    const {
        field: { ref, ...field },
    } = useController<SecretFormValues, typeof name>({ name });

    return (
        <FormField<SecretFormValues>
            name={name}
            label={
                <Label>
                    <FormattedMessage id="secret.type.label" />
                </Label>
            }
        >
            <SelectInput
                {...field}
                popupShouldIgnoreParentContainer
                options={Object.values(SecretType).map(type => ({
                    label: <SelectOption type={type} />,
                    value: type,
                }))}
                customOptionStyle={felaRules.option}
            />
        </FormField>
    );
};
