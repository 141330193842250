import { pick } from 'lodash';

import { SecretType, TOTP_VALUE_PLACEHOLDER } from '../../constants';
import { secretFormSchema, type SecretFormValues } from '../../schemas';

export const compareByName = function <
    T extends {
        name?: string;
    },
>(a: T, b: T, locale?: string) {
    return a.name?.localeCompare(b?.name ?? '', locale) ?? 0;
};

export function getSecretsWithGroups<S, G>(
    secrets: S[],
    isNextGroup: (nextSecret: S, currentSecret: S) => boolean,
    createGroup: (secret: S) => G,
    updateGroup?: (group: G, secret: S) => void,
) {
    const secretsWithGroups: (G | S)[] = [];

    let lastGroup: G;

    secrets.forEach((secret, index) => {
        if (index === 0 || isNextGroup(secrets[index - 1], secrets[index])) {
            lastGroup = createGroup(secret);
            secretsWithGroups.push(lastGroup);
        }

        updateGroup?.(lastGroup, secret);

        secretsWithGroups.push(secret);
    });

    return secretsWithGroups;
}

export const prepareSecretPayload = (values: SecretFormValues): Record<string, any> => {
    const payload = { ...values };

    if (payload.type === SecretType.Password) {
        // If the value is the placeholder one, the field haven't been updated, API would reject the value, thus omit the value
        if (payload.TOTP === TOTP_VALUE_PLACEHOLDER) {
            delete payload.TOTP;

            // If the value is null, it means we want to remove previously existing TOTP – we need to send null to the API.
        } else if (typeof payload.TOTP === 'string') {
            // Remove all spaces in TOTP value
            payload.TOTP = payload.TOTP.replace(/\s/g, '');
        }

        // Never send this helper field to API
        delete payload.hasTOTP;
    }

    payload.groups = values.groups?.map(({ id, accessPermissions }) => ({
        id,
        accessPermissions,
    }));

    payload.whitelistUsers = values.whitelistUsers?.map(({ id, accessPermissions }) => ({
        id,
        accessPermissions,
    }));

    // Strip the payload to only include fields that are in the schema
    const payloadBySchema = filterValuesBySchema(payload);

    return payloadBySchema;
};

export const filterValuesBySchema = (values: SecretFormValues) => {
    const fieldsInSchema = secretFormSchema.optionsMap.get(values.type)?.keyof().options;

    const valuesBySchema = pick(values, fieldsInSchema);

    return valuesBySchema;
};
