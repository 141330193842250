import { FormProvider, useForm, type UseFormReturn, type UseFormProps } from 'react-hook-form';

import { type HTMLAttributes } from 'react';

import type { SubmitError, UnknownFormValues } from '../../types';

import { useLocalizedResolver, type UnknownFormSchema } from '../../hooks';
import { FormSubmitProvider } from './FormSubmitProvider';

export interface FormProps<FormSchema extends UnknownFormSchema, FormValues extends UnknownFormValues>
    extends Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
    schema: FormSchema;
    children: React.ReactNode;
    onSubmit: (values: FormValues, form: UseFormReturn<FormValues>) => Promise<SubmitError<FormValues> | void> | void;
    defaultValues?: UseFormProps<FormValues>['defaultValues'];
}

export const Form = <FormSchema extends UnknownFormSchema, FormValues extends UnknownFormValues>({
    schema,
    children,
    defaultValues,
    onSubmit,
    ...rest
}: FormProps<FormSchema, FormValues>) => {
    const resolver = useLocalizedResolver(schema);
    const form = useForm<FormValues>({
        resolver,
        defaultValues,
        mode: 'onTouched',
    });

    const submit = form.handleSubmit(values => onSubmit(values, form));

    return (
        <FormProvider<FormValues> {...form}>
            <FormSubmitProvider submit={submit}>
                <form onSubmit={submit} {...rest}>
                    {children}
                </form>
            </FormSubmitProvider>
        </FormProvider>
    );
};
